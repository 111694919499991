.sobre-container-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 60px;
}

.sobre-container-banner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  background-image: url("../../img/sky.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.sobre-banner-nuvem {
  position: absolute;
  background-image: url("../../img/nuvem.png");
  background-repeat: repeat;
  background-position: 11%;
  background-size: cover;
  height: 40%;
  width: 100%;
  top: 0;
  left: 0;
  animation: animatedBackground 720s linear infinite;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}

.sobre-container-titulo {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
  z-index: 1;
}

.sobre-container-titulo > span {
  font-family: "Dancing Script", cursive;
  font-size: 55px;
}

.sobre-container-titulo > p {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  width: 70%;
}

.sobre-container-conteudo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85%;
  padding: 4%;
  margin: 5%;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.45);
}

.sobre-container-missao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  background-color: #f2f8ff;
  margin: 5%;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.45);
}

.sobre-container-section-b {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.sobre-container-conteudo-texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 53%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  text-align: justify;
}

.sobre-container-titulo-selfc {
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  margin-bottom: 20px;
}

.container-conteudo-historia {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container-conteudo-historia-imagens {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36%;
  height: 500px;
  background-image: url("../../img/selfc-casa.jpeg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.sobre-container-missao-titulo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 20%;
}

.sobre-container-missao-titulo > span {
  font-family: "Dancing Script", cursive;
  font-size: 66px;
  font-style: oblique;
}

.sobre-container-missao-icone {
  width: 85px;
  height: 100px;
  background-image: url("../../img/missao_alvo.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-right: 20px;
}

.sobre-container-missao-descricao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 50%;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  text-align: center;
}

@media (max-width: 1200px) {
  .sobre-container-titulo > span {
    font-size: 40px;
  }

  .sobre-container-titulo > p {
    font-size: 13px;
  }

  .sobre-container-titulo-selfc {
    font-size: 20px;
  }

  .container-conteudo-historia {
    flex-direction: column;
  }

  .sobre-container-conteudo-texto {
    width: 80%;
    font-size: 14px;
  }

  .container-conteudo-historia-imagens {
    height: 360px;
  }

  .sobre-container-missao-titulo > span {
    font-size: 50px;
  }

  .sobre-container-missao-icone {
    width: 70px;
  }

  .sobre-container-missao-descricao {
    font-size: 16px;
  }
}

@media (max-width: 430px) {

  .sobre-container-conteudo {
    box-shadow: none;
  } 

  .sobre-container-titulo > p {
    font-size: 10px;
    width: 88%;
  }

  .sobre-container-titulo-selfc {
    font-size: 13px;
  }

  .sobre-container-conteudo-texto {
    width: 80%;
    font-size: 11px;
  }

  .container-conteudo-historia-imagens {
    height: 305px;
    width: 80%;
  }

  .sobre-container-missao-descricao {
    font-size: 11px;
    width: 80%;
  }

  .sobre-container-missao-titulo > span {
    font-size: 40px;
  }

  .sobre-container-missao-icone {
    width: 57px;
  }

  .sobre-container-missao {
    height: 45vh;
  }
}

@media (max-width: 370px) {
  .sobre-container-titulo > p {
    font-size: 9px;
  }

  .sobre-container-titulo > span {
    font-size: 30px;
  }

  .sobre-container-titulo-selfc {
    font-size: 11px;
  }

  .sobre-container-conteudo-texto {
    width: 84%;
    font-size: 10px;
  }

  .container-conteudo-historia-imagens {
    height: 245px;
  }
}

@media (max-width: 320px) {
  .sobre-container-missao {
    height: 60vh;
  }
}

@media (max-height: 500px) {
  .sobre-container-missao {
    height: 75vh;
  }

  .sobre-container-banner {
    height: 45vh;
  }
}
